import * as React from 'react';

export const Logo: React.FC = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 783.37 159.8" className={props.className} style={props.css}>
    <path d="M243.18,678.19a38.62,38.62,0,0,1-38.58-38.57,5.52,5.52,0,0,1,11,0,27.55,27.55,0,0,0,55.1,0V556.86a5.51,5.51,0,0,1,11,0v82.76A38.61,38.61,0,0,1,243.18,678.19Z" transform="translate(-204.6 -518.39)"/>
    <path d="M308,645a5.51,5.51,0,0,1-5.51-5.51V556.86a5.51,5.51,0,0,1,11,0v82.66A5.51,5.51,0,0,1,308,645Z" transform="translate(-204.6 -518.39)"/>
    <path d="M437.58,645a5.51,5.51,0,0,1-5.51-5.51V556.86a5.51,5.51,0,0,1,11,0v82.66A5.51,5.51,0,0,1,437.58,645Z" transform="translate(-204.6 -518.39)"/>
    <path d="M405.84,645a5.51,5.51,0,0,1-5.51-5.51V590a27.55,27.55,0,1,0-55.1,0v49.5a5.51,5.51,0,1,1-11,0V523.9a5.51,5.51,0,1,1,11,0V563a38.53,38.53,0,0,1,66.12,27v49.5A5.51,5.51,0,0,1,405.84,645Z" transform="translate(-204.6 -518.39)"/>
    <path d="M548.9,596.43c10.22,2.16,15.79,5.63,15.79,15.13,0,8.46-6.48,14.82-18.22,14.82-14.94,0-18-7.74-19.59-13a4.1,4.1,0,0,1-.13-.85,2.93,2.93,0,0,1,2.49-2.75,6,6,0,0,1,1.11-.13,2.82,2.82,0,0,1,2.89,2c1.18,4.4,3.34,9.31,12.91,9.31,7.47,0,12.12-3.6,12.12-9.24,0-5.37-2.95-7.73-10.68-9.31l-5.24-1.11c-9.83-2-14.29-5.77-14.29-14.35,0-6.88,5.11-13.57,17.43-13.57,11.8,0,15.86,6.29,17.3,11a2.68,2.68,0,0,1,.2,1,2.8,2.8,0,0,1-2.42,2.63,6.38,6.38,0,0,1-1.12.13,2.91,2.91,0,0,1-2.88-1.9c-1.05-3.74-3.8-7.41-11.08-7.41-7.6,0-11.07,3.8-11.07,8.13,0,4.72,2.29,7.14,9.63,8.58Z" transform="translate(-204.6 -518.39)"/>
    <path d="M583.63,623c0,1.83-1.31,2.75-3.08,2.75s-3.08-.92-3.08-2.82V581.35c0-5,2-7.08,6.69-7.08h14.55c10.35,0,16.18,5.31,16.18,14.62,0,9.83-6,15.53-16.45,15.53H583.63Zm14.68-24.05c6.62,0,10.16-3.54,10.16-9.9,0-5.9-3.6-9.18-10.16-9.18H585.73c-1.44,0-2.1.86-2.1,2.3v16.78Z" transform="translate(-204.6 -518.39)"/>
    <path d="M666.07,621.85a3.16,3.16,0,0,1,.2,1.12,2.63,2.63,0,0,1-2.16,2.55,5.55,5.55,0,0,1-1.44.2,2.59,2.59,0,0,1-2.56-1.7l-1.64-4.2c-4.72-12.78-6.55-17.43-13.1-17.43H633.31V623c0,1.83-1.25,2.75-3,2.75s-3.14-.92-3.14-2.82V581.35c0-5,2-7.08,6.68-7.08h15.34c9.5,0,14.74,5.05,14.74,13.11,0,7.54-4.26,11.47-10.35,12.78,3.93,1.18,6,5.57,10.88,17.83ZM648.38,597c5.57,0,9.24-3.48,9.24-8.72,0-5.57-3-8.59-8.85-8.59h-13.3c-1.38,0-2.16.72-2.16,2.3v15Z" transform="translate(-204.6 -518.39)"/>
    <path d="M684.42,617.53c0,1.51,1,2.09,2.43,2.09h23.46a2.53,2.53,0,0,1,2.62,2.82,2.62,2.62,0,0,1-2.62,2.89H685c-4.66,0-6.69-2-6.69-7v-37c0-5,2-7.08,6.69-7.08h24.77a2.53,2.53,0,0,1,2.62,2.82,2.59,2.59,0,0,1-2.62,2.89H686.65c-1.57,0-2.23.72-2.23,2.29v13.57h22.35a2.52,2.52,0,0,1,2.56,2.81,2.58,2.58,0,0,1-2.56,2.76H684.42Z" transform="translate(-204.6 -518.39)"/>
    <path d="M727.35,623.88a2.54,2.54,0,0,1-2.56,1.84,4.58,4.58,0,0,1-1.31-.2,2.9,2.9,0,0,1-2.29-2.68,3.38,3.38,0,0,1,.19-1.12L739.21,577a4.44,4.44,0,0,1,4.52-3.08,4.54,4.54,0,0,1,4.65,3.08l17.83,44.89a2.3,2.3,0,0,1,.26,1.12,2.85,2.85,0,0,1-2.36,2.62,3.7,3.7,0,0,1-1.18.2,2.72,2.72,0,0,1-2.68-1.84l-5.18-13.57H732.39Zm25.75-19-7.27-19.07a63.35,63.35,0,0,1-2-6.23,53.31,53.31,0,0,1-2,6.29l-7.4,19Z" transform="translate(-204.6 -518.39)"/>
    <path d="M778.33,581.35c0-5,2-7.08,6.69-7.08h11.66c14.49,0,23,9.18,23,25,0,16.32-8.65,26.09-23,26.09H785c-4.66,0-6.69-2-6.69-7Zm6.23,36a1.93,1.93,0,0,0,2.09,2.22h9.7c10.62,0,16.85-7.53,16.85-20.31,0-12.52-5.84-19.4-16.65-19.4h-9.83a2,2,0,0,0-2.16,2.16Z" transform="translate(-204.6 -518.39)"/>
    <path d="M875.06,576.57c0-1.84,1.38-2.69,3.28-2.69,1.7,0,3,.85,3,2.69v35.26c0,9.43-5.18,14.41-14.15,14.41a15.71,15.71,0,0,1-9.44-2.68,13.17,13.17,0,0,1-4.39-5.84,3.34,3.34,0,0,1-.2-1.11,3.1,3.1,0,0,1,2.16-2.88,5.69,5.69,0,0,1,1.51-.27,2.7,2.7,0,0,1,2.69,1.77,7.41,7.41,0,0,0,7.6,5.31c5.18,0,7.93-3.21,7.93-9.3Z" transform="translate(-204.6 -518.39)"/>
    <path d="M940.33,599.83c0,16.13-8.78,26.55-22.8,26.55S894.59,616,894.59,599.77c0-16.06,8.85-26.48,22.94-26.48S940.33,583.65,940.33,599.83Zm-39.25,0c0,12.65,6.42,20.84,16.45,20.84,10.22,0,16.32-8.06,16.32-21.23,0-12.39-6.36-20.51-16.32-20.51C907.37,578.93,901.08,587.18,901.08,599.83Z" transform="translate(-204.6 -518.39)"/>
    <path d="M982.08,575.19a2.63,2.63,0,0,1,2.36-1.37,3.64,3.64,0,0,1,1.77.52A3.13,3.13,0,0,1,988,577a2.48,2.48,0,0,1-.46,1.44l-17.3,26V623c0,1.77-1.31,2.69-3.08,2.69S964,624.8,964,623V604.36l-17.43-26.09a2.54,2.54,0,0,1-.39-1.31,3.34,3.34,0,0,1,1.9-2.69,4.45,4.45,0,0,1,1.76-.45,2.77,2.77,0,0,1,2.36,1.37l14.95,23.33Z" transform="translate(-204.6 -518.39)"/>
  </svg>
);
