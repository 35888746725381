import { css } from '@emotion/react';

export const alert = css`
  color: #fff !important;
  margin: 2rem auto 0 auto;
  max-width: 540px;
  padding: 2rem;
  transition: all 300ms ease;
`;

export const alertSuccess = css`
  background-color: #389f57;
`;

export const alertError = css`
  background-color: tomato;
`;

export const styledForm = css`
  background-color: rgba(255, 255, 255, 0);
  background-position-y: center;
  background-repeat: no-repeat;
  border-color: rgb(0, 0, 0);
  border-radius: 0px;
  border-style: none;
  border-width: 0px;
  box-sizing: border-box;
  padding: 10px 5px;
  width: 100%;
`;

export const form = css`
  fieldset legend {
    text-transform: uppercase;
  }

  textarea {
    padding: 1.5rem;
  }
`;

export const styledField = css`
  margin: 2rem 0;

  input,
  textarea {
    width: 100%;
  }
`;

export const styledInput = css`
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgb(36, 36, 36);
  border-left-color: rgb(36, 36, 36);
  border-radius: 2px;
  border-right-color: rgb(36, 36, 36);
  border-top-color: rgb(36, 36, 36);
  border: 1px solid rgb(36, 36, 36);
  box-sizing: border-box;
  color: rgb(36, 36, 36);
  font-size: 1.5rem;
  font-weight: 400;
  justify-content: flex-start;
  letter-spacing: 0px;
  position: relative;
  text-align: left;
  -moz-box-pack: start;
  width: 100%;

  & + button {
    margin-top: 2rem;
  }

  &[type="text"],
  &[type="email"] {
    height: 48px;
    padding: 0px 0px 0px 1.5rem;
  }

  fieldset & {
    margin: 1rem auto;
  }
`;

export const styledSubmit = css`
  justify-content: flex-start;
  padding: 10px 6px 10px 0px;
  position: relative;
  -moz-box-pack: start;
  width: 100%;

  @media (min-width: 600px) {
    // display: flex;
    // flex: 0 1 auto;
    // width: auto;
  }
`;
