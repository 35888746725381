import { Link } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import React from 'react';
import { Logo } from '../Logo';

import { css } from '@emotion/react';

interface SiteNavLogoProps {
  logo?: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
}

export const SubscribeLogo: React.FC<SubscribeProps> = props => {
  return (
    <Link to="/">
      <Logo css={SubscribeOverlayLogo} className="subscribe-overlay-logo" />
    </Link>
  );
};

const SubscribeOverlayLogo = css`
  position: fixed;
  top: 2em;
  left: 2em;
  height: 30px;
  fill: #FFF;
`;

export default SubscribeLogo;
